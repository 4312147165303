import { Space } from 'antd';
import { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { storeStartLoading } from '../../actions/storeCrud';
import { EditStoreModal } from '../modal/EditStoreModal';
import { AddStore } from '../ui/AddStore';
import { BtnDelete } from '../ui/BtnDelete';
import { BtnEdit } from '../ui/BtnEdit';
import { BtnLogout } from '../ui/BtnLogout';
import { DataGrid, esES } from '@mui/x-data-grid';
import './store.css';
import { DeleteStoreModal } from '../modal/DeleteStoreModal';
import { BtnBack } from '../ui/BtnBack';
import { BtnReload } from '../ui/BtnReload';

const columns = [
    {
        headerName: 'Código',
        field: 'code',
        flex: 1,
    },
    {
        headerName: 'Nombre Tienda',
        field: 'name',
        flex: 1,
    },
    {
        headerName: 'Dirección',
        field: 'address',
        flex: 1,
    },
    {
        headerName: 'Población',
        field: 'town',
        flex: 1,
    },
    {
        headerName: 'Ciudad',
        field: 'city',
        flex: 1,
    },
    {
        headerName: 'Acción',
        field: 'action',
        flex: 1,
        renderCell: (record) => (
            <Space size="middle">
                <BtnEdit  store= { record.row }/>
                <BtnDelete  store= { record.row }/>
            </Space>
        ),
    },
];

export const StoreScreen = ( {history} ) => {

    const {stores} = useSelector(state => state.store);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        dispatch( storeStartLoading())
        
    }, [dispatch])

    return (
        <div>
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Tiendas</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <section id="action-button">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                        <Link to='/store/register'>
                            <AddStore></AddStore>
                        </Link>
                        <BtnReload props={'store'}/>                        
                        </div>                     
                    </div>
                </div>
            </section>
            <div>
            <div className='table-container'>
                <div className="table-style-store">
                <DataGrid
                        rows={stores}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </div>
            <EditStoreModal/>
            <DeleteStoreModal/>
        </div>
        
        </div>
        
    )
}