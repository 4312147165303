import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { storeDelete, storeSetActive, storeStartLoading } from '../../actions/storeCrud';
import { userDelete, userSetActive, userStartLoading } from '../../actions/userCrud';
import { uiOpenModalDelete } from '../../actions/uiDelete';

export const BtnDelete = (props) => {

    const dispatch = useDispatch();

    const handleDelete = async () => {

        if(props?.store){
            dispatch( storeSetActive(props))
        } else {
            dispatch( userSetActive(props))
        }
        dispatch( uiOpenModalDelete() )
    }

    return (
        <button className="btn btn-danger btn-padding" onClick={handleDelete}>
            <DeleteOutlined />
        </button>
    )
}
