import {} from './actions/appconfiguration';
import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from './InitialApp';


ReactDOM.render( <
    App / > ,
    document.getElementById('root')
);