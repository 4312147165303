import React from 'react';
import './register.css';
import { useForm } from '../../hooks/useForm'
import { useDispatch } from 'react-redux';
import { startRegisterStore, storeStartLoading } from '../../actions/storeCrud';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';

export const RegisterStoreScreen = ({history}) => {

    const dispatch = useDispatch()

    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        code: '',
        name: '',
        address: '',
        town: '',
        city: '',
    })

    let { code, name, address, town,  city} = formRegisterValues;

    const handleRegister = async (e) => {

        e.preventDefault();

        await dispatch( startRegisterStore(code, name, address, town,  city));
        dispatch(storeStartLoading());
    }

    return (
        <div>
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Registro de Tienda</h1>                        
                        </div>
                        <div className="col-auto"><BtnLogout/></div>                        
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 register-form-2">
                        <form onSubmit={ handleRegister } className="mmk-form">
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Código</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Código"
                                        name="code"
                                        value= { code }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Nombre Tienda</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nombre Tienda"
                                            name="name"
                                            value= { name }
                                            onChange= { handleRegisterInputChange }
                                        />
                                    </div>
                                </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Dirección</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Dirección"
                                        name="address"
                                        value= { address }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Población</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Población"
                                        name="town"
                                        value= { town }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >Ciudad</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Ciudad"
                                        name="city"
                                        value= { city }
                                        onChange= { handleRegisterInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end mb-2">
                                <div className="col-12 ">
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary">Agregar Tienda</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}