const baseUrl = process.env.REACT_APP_API_URL


const fetchSinToken = async ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    
    if ( method === 'GET' ) {
        return fetch( url );
    } else if (endpoint === 'imagen'){
        return fetch( url, {
            method,
            
            body:  data 
        });
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        });
    }
}

const fetchConToken = async ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    const token = localStorage.getItem('token') || '';
    
        if ( method === 'GET' ) {
            return fetch( url,{
                method,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            } );
            //endpoint === 'imagen' || (endpoint.includes('producto') && data?.constructor?.name === 'FormData')
        } else if (data?.constructor?.name === 'FormData'){
            return fetch( url, {
                method,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body:  data 
            });
        } else {
            return fetch( url, {
                method,
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify( data )
            });
        }
}


export {
    fetchSinToken, fetchConToken
}