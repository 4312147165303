import React from 'react';
import { LineChartOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { filterMovement, movementStartLoading } from '../../actions/movement';

import moment from 'moment';

export const BtnMovement = (props) => {

    const dispatch = useDispatch();

    const date = moment();
    let dateWeek = moment();
    const intervalMovement = window.APP_CONFIG.daymovement;
    dateWeek = dateWeek.subtract(intervalMovement, "days");
    dateWeek = dateWeek;

    const movement = {
        store: props.movement?.store_id,
        sdate: dateWeek,
        edate: date,
        productcode: props.movement?.productcode,
        description: '',
        direction: '',
        units: '',
        reference: '',
        blockreason: '',
    }

    const { store, sdate, edate, productcode, description, direction, units, reference, blockreason} = movement

    const handleClick = () => {

        dispatch( filterMovement(store, sdate, edate, productcode, description, direction, units, reference, blockreason))
    }

    return (
        <button className="btn btn-movement btn-padding" props={props} onClick={handleClick}>
            <LineChartOutlined />
        </button>
    )
}
