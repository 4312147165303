import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startChecking, startLogout} from "./auth";

export const userStartLoading = () => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`user`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( userLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

const userLoaded = (user) => ({
    type: types.userLoaded,
    payload: user
})

export const startRegisterUser = (login, password, store_id, name,  email, active, role_id) => {

    
    
    return async( dispatch ) => {

        const resp = await fetchConToken( `user`, { login, password, store_id, name,  email, active, role_id }, 'POST');
        const body = await resp.json();

        if( resp.status === 201 && body ){
            const msg = 'El usuario ' + name + ' se ha registrado correctamente.';
            Swal.fire('Registrado', msg, 'success')
        } else {
            Swal.fire('Error', body.detail, 'error')
        }
    }
}

export const userStartUpdated = (user) => {

    return async( dispatch ) => {
        let id =  user.id
        try{
            const resp = await fetchConToken(`user/${id}`, user, 'PUT') ;
            const body = await resp.json();
            if ( resp.status === 200 && body ){
                dispatch( userUpdated(body.user) )
            } else {
                Swal.fire('Error', body.msg, 'error')
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

const userUpdated = ( user ) => ({
    type: types.userUpdated,
    payload: user
})

export const userSetActive = (user) => ({
    type: types.userSetActive,
    payload: user
})

export const userClearActive = (user) => ({type: types.userClearActive})


export const userDelete = (props) => {


    return async( dispatch, getState ) => {


        const { id, name } = props?.user;

        try{
            const resp = await fetchConToken(`user/${id}`, {}, 'DELETE');
            if ( resp.status === 200  ){
                Swal.fire('Eliminado', 'Usuario: ' + name + ' eliminado correctamente.', 'success')
                dispatch( userDeleted() )
            } else {
                const body = await resp.json();
                Swal.fire('Error', body.detail, 'error')
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

export const userDeleted = (user) => ({type: types.userDeleted})