import { types } from "../types/types"

const initialState = {
    modalOpenStore: false,
}

export const uiStoreReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiOpenModalStore:
            return {
                ...state,
                modalOpenStore: true
            }

        case types.uiCloseModalStore:
            return {
                ...state,
                modalOpenStore: false
            }

        default:
            return state;
    }
}