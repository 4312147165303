import React from 'react';
import './btn.css';

export const BtnBack = ({history}) => {

    const icon = '<';
    const handleReturn = () => {
        history.goBack();
    }

    return (
        <button className="btn btn-error btn-back" onClick={handleReturn}>
            <span className="btn-back-icon">
                {icon}
            </span>
        </button>
    )
}