import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, HashRouter, Switch } from 'react-router-dom'
import { startChecking } from '../actions/auth'
import { LoginScreen } from '../components/auth/LoginScreen'
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { RegisterUserScreen } from '../components/register/RegisterUserScreen'
import { StockScreen } from '../components/stock/StockScreen'
import { MenuScreen } from '../components/menuAdmin/MenuScreen'
import { UserScreen } from '../components/user/UserScreen'
import { RegisterStoreScreen } from '../components/register/RegisterStoreScreen'
import { StoreScreen } from '../components/store/StoreScreen'
import { MovementScreen } from '../components/movements/MovementScreen'

export const AppRouter = () => {

    const dispatch = useDispatch();

    const { checking, token } = useSelector(state => state.auth)

    useEffect(() => {
        
        dispatch( startChecking() )
        
    }, [dispatch])

    if ( checking ) {
        return <h5>ESPERE...</h5>
    }
    return (
        <HashRouter>
            <div>
                <Switch>
                    
                    <PublicRoute exact path="/login" component={LoginScreen} isAuthenticated={ !!token }/>
                    
                    
                    <PrivateRoute exact path="/store/register" component={RegisterStoreScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/user/register" component={RegisterUserScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/movements/:productId - :storeId" component={MovementScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/movements" component={MovementScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/store" component={StoreScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/user" component={UserScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/stock" component={StockScreen} isAuthenticated={ !!token }/>
                    <PrivateRoute exact path="/" component={MenuScreen} isAuthenticated={ !!token }/>:userId

                    <Redirect to="/"/>
                </Switch>
            </div>
        </HashRouter>
    )
}
