import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImportModal } from '../import/ImportModal';
import { DeleteStoreModal } from '../modal/DeleteStoreModal';
import { BtnLogout } from '../ui/BtnLogout';
import { ImportBtn } from '../ui/ImportBtn';
import { Spinner } from '../ui/Spinner';
import { StockBtn } from '../ui/StockBtn';
import { TiendaBtn } from '../ui/TiendaBtn';
import { UserBtn } from '../ui/UserBtn';
import { MovementBtn } from '../ui/MovementBtn';
import './menu.css';
import { ImportMovementModal } from '../import/ImportMovementModal';
import { ImportMovementBtn } from '../ui/ImportMovementBtn';

export const MenuScreen = () => {

    const { rol } = useSelector(state => state.auth)
    const { loading } = useSelector(state => state.spinner);

    return  loading ? (
        <Spinner />
        ) : ( 
    <>
        <div className="container">
            
            <ImportModal/>
            <div className="row">
                <div className="col-md-4 menu-form">
                    <img className='menu-logo' alt="" src={require("../../assets/img/logo-encolate.png")}/>
                    <h3 className='menu-title'>Menu</h3>
                    { rol === 1 &&
                        <div className="btnMenu">
                            <Link to='/user'>
                                <UserBtn></UserBtn>
                            </Link>
                        </div>
                    }
                    { rol === 1 &&
                        <div className="btnMenu">
                            <Link to='/store'>
                                <TiendaBtn></TiendaBtn>
                            </Link>
                        </div>
                    }
                    <div className="btnMenu">
                        <Link to='/stock'>
                            <StockBtn></StockBtn>
                        </Link>
                    </div>
                    <div className="btnMenu">
                        <Link to='/movements'>
                            <MovementBtn></MovementBtn>
                        </Link>
                    </div>
                    { rol === 1 &&
                        <div className="btnMenu">
                            <ImportBtn></ImportBtn>
                        </div>
                    }
                    { rol === 1 &&
                        <div className="btnMenu">
                            <ImportMovementBtn></ImportMovementBtn>
                        </div>
                    }
                    <div className="btnMenu">
                        <BtnLogout/>
                    </div>
                </div>
                <ImportModal/>
                <ImportMovementModal/>
            </div>
        </div>
    </>    
    )
}