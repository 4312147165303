import { types } from '../types/types'

const initialState = {
    stores: [],
}

export const storeReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.storeLoaded:
            return {
                stores: [...action.payload]
            }

        case types.storeSetActive:

            return {
                ...state,
                activeStore: action.payload
            }

        case types.storeDeleted:

            return{
                ...state,
                stores: state.stores.filter(
                    e => ( e.id !== state.activeStore.id )
                ),
                activeStore: null
            }

        default:
            return state;
    }
}

export default storeReducer;