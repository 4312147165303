import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";

export const userStock = (id) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stock/`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( stockLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}

const stockLoaded = (stock) => ({
    type: types.stockLoaded,
    payload: stock
})

export const filterStock = (store, productcode, zone, street, position, altitude, eancode, units, block) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stock?store_id=${store}&zone=${zone}&productcode=${productcode}&street=${street}&position=${position}&altitude=${altitude}&eancode=${eancode}&units=${units}&block=${block}`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( stockLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}