import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { storeStartLoading } from '../../actions/storeCrud';
import { filterStock } from '../../actions/stock';
import { userStartLoading } from '../../actions/userCrud';

import moment from 'moment';
import { filterMovement } from '../../actions/movement';

export const BtnReload = ({props, data}) => {

    const dispatch = useDispatch();
    const { store } = useSelector(state => state.auth);

    const handleClick = () => {

        if (props === 'store')
        {
            dispatch(storeStartLoading());
        } else if (props === 'user')
        {
            dispatch( userStartLoading());
        } else if (props === 'movement')
        {
            const { store : store2, sdate, edate, productcode, description, direction, units, reference, blockreason} = data
            let sdateparam = sdate ? moment(sdate, "yyyy-MM-DD") : null;
            let edateparam = edate ? moment(edate, "yyyy-MM-DD") : null;
            dispatch( filterMovement(store2, sdateparam, edateparam, productcode, description, direction, units, reference, blockreason) )
        } else 
        {
            const { store, productcode, zone, street, position, altitude, eancode, units, block } = data
            dispatch( filterStock(store, productcode, zone, street, position, altitude, eancode, units, block) )
        }
    }

    return (
        <button className="btn btn-color" props={props} onClick={handleClick}>
            <ReloadOutlined />
        </button>
    )
}
