import React from 'react';
import './btn.css';

export const AddStore = () => {

    return (
        <button className="btn btn-success btn-margin-right" >
            <span>
                AGREGAR TIENDA
            </span>
        </button>
    )
}