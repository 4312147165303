import { combineReducers } from "redux";
import { authReducer } from './authReducer';
import { stockReducer } from './stockReducer';
import { movementReducer } from './movementReducer';
import { userReducer } from "./userReducer";
import { storeReducer } from "./storeReducer";
import { uiStoreReducer } from "./uiStoreReducer";
import { uiDeleteReducer } from "./uiDeleteReducer";
import { uiUserReducer } from "./uiUserReducer";
import { importReducer } from "./importReducer";
import { uiReducer } from "./uiReducer";
import { spinnerReducer } from "./spinnerReducer";

export const rootReducer = combineReducers({

    auth: authReducer,
    stock: stockReducer,
    movement: movementReducer,
    user: userReducer,
    store: storeReducer,
    uiStore: uiStoreReducer,
    uiDelete: uiDeleteReducer,
    uiUser: uiUserReducer,
    import: importReducer,
    ui: uiReducer,
    spinner: spinnerReducer,
});

export default rootReducer;