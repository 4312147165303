import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { storeStartLoading, storeStartUpdated } from '../../actions/storeCrud';
import { uiCloseModalStore } from '../../actions/uiStore';
import "./modal.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const initEvent = {
    code: '',
    name: '',
    address: '',
    town: '',
    city: '',
}

export const EditStoreModal = () => {

    const { modalOpenStore} = useSelector(state => state.uiStore)
    const { activeStore } = useSelector(state => state.store)
    const dispatch = useDispatch()
    const [titleValid, setTitleValid] = useState(true)
    const [formValues, setFormValues] = useState(initEvent);
    

    useEffect(() => {
        if(activeStore){
            setFormValues( activeStore.store );
        } else {
            setFormValues( initEvent )
        }
    }, [activeStore, setFormValues])
    
    const { code, name, address, town,  city} = formValues;

    const handleInputChange = ({ target }) => {

        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }

    const closeModal = () => {
        dispatch( uiCloseModalStore() )
        setFormValues( initEvent )
    }
    const handleSubmitForm = async (e) => {
        e.preventDefault();


        if ( name.trim().length < 2 ) {
            return setTitleValid(false);
        }
        
        await dispatch( storeStartUpdated( formValues ));
        setFormValues( initEvent );
        setTitleValid(true);
        closeModal();
        dispatch( storeStartLoading());
    }
    return (
        <Modal 
        isOpen={ modalOpenStore }
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={ 200 }
        className= "modal modal-store"
        overlayClassName= "modal-fondo"
        >
            <h1 className='title'> Editar Tienda </h1>
            <hr />
            <form className="mmk-form" onSubmit={ handleSubmitForm }>
                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Código</label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Código"
                            name="code"
                            autoComplete="off"
                            value={ code }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Nombre Tienda</label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Nombre Tienda"
                            name="name"
                            autoComplete="off"
                            value={ name }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Dirección </label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Dirección"
                            name="address"
                            autoComplete="off"
                            value={ address }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Población </label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Población"
                            name="town"
                            autoComplete="off"
                            value={ town }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Ciudad </label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Ciudad"
                            name="city"
                            autoComplete="off"
                            value={ city }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row justify-content-end mb-2">
                    <div className="col-12">
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-primary">GUARDAR</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}