import { types } from "../types/types"

const initialState = {
    modalOpenUser: false,
}

export const uiUserReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiOpenModalUser:
            return {
                ...state,
                modalOpenUser: true
            }

        case types.uiCloseModalUser:
            return {
                ...state,
                modalOpenUser: false
            }

        default:
            return state;
    }
}