import React from 'react';
import { useDispatch} from 'react-redux';
import { userStartLoading } from '../../actions/userCrud';
import './btn.css';

export const UserBtn = () => {

    const dispatch = useDispatch()

    const handleUser = () => {
        dispatch( userStartLoading() )
    }

    return (
        <button className="btn  margin_right_envelope btnColor" onClick={handleUser}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                USUARIO
            </span>
        </button>
    )
}