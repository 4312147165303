import { types } from '../types/types'

const initialState = {
    import: [],
}

export const importReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.importLoaded:
            return {
                import: [...action.payload]
            }

        default:
            return state;
    }
}

export default importReducer;