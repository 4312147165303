import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userStock } from '../../actions/stock';
import { storeStartLoading } from '../../actions/storeCrud';
import './btn.css';

export const StockBtn = ( {props} ) => {

    const dispatch = useDispatch()
    const { store } = useSelector(state => state.auth)


    const handleStock= () => {
        dispatch(storeStartLoading());
        dispatch( userStock(store))
    }

    return (
        <button className="btn btn-danger btnColor" onClick={handleStock}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                STOCK TIENDAS
            </span>
        </button>
    )
}