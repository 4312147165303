import { types } from '../types/types'

const initialState = {
    loading: false,
}

export const spinnerReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.spinnerLoaded:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
}

export default spinnerReducer;