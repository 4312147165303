import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";

export const movementStartLoading = (id) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stockmovement/`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( movementLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
        }  
    }
}

const movementLoaded = (movement) => ({
    type: types.movementLoaded,
    payload: movement
})

export const filterMovement = (store, sdate, edate, productcode, description, direction, units, reference, blockreason) => {
    sdate = (sdate) ? sdate.format("YYYY-MM-DD") : "";
    edate = (edate) ? edate.format("YYYY-MM-DD") : "";

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stockmovement?store_id=${store}&sdate=${sdate}&edate=${edate}&productcode=${productcode}&description=${description}&direction=${direction}&units=${units}&reference=${reference}&blockreason=${blockreason}`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( movementLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
            //dispatch(startChecking())
        }  
    }
}