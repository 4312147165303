import { types } from "../types/types"

const initialState = {
    modalOpen: false,
    modalMovementOpen: false,
}

export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiOpenModal:
            return {
                ...state,
                modalOpen: true
            }

        case types.uiCloseModal:
            return {
                ...state,
                modalOpen: false
            }
        case types.uiMovementOpenModal:
            return {
                ...state,
                modalMovementOpen: true
            }

        case types.uiMovementCloseModal:
            return {
                ...state,
                modalMovementOpen: false
            }

        default:
            return state;
    }
}