import React from 'react';
import { useDispatch} from 'react-redux';
import { uiMovementOpenModal } from '../../actions/ui';
import './btn.css';

export const ImportMovementBtn = () => {

    const dispatch = useDispatch()

    const handleImport = () => {
        dispatch( dispatch( uiMovementOpenModal()))
    }

    return (
        <button className="btn  margin_right_envelope btnColor" onClick={handleImport}>
            <i className="fas fa-user-alt icon"> </i>
            <span className="navbar-brand user">
                IMPORTAR MOVIMIENTOS
            </span>
        </button>
    )
}