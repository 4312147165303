import React from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { storeDelete, storeStartLoading } from '../../actions/storeCrud';
import { uiCloseModalDelete } from '../../actions/uiDelete';
import { userDelete, userStartLoading } from '../../actions/userCrud';
import "./modal.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

export const DeleteStoreModal = () => {

    const { modalOpenDelete } = useSelector(state => state.uiDelete);
    const { activeStore } = useSelector(state => state.store);
    const { activeUser} = useSelector(state => state.user);
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch( uiCloseModalDelete() )
    }

    const handleReturn = () =>{
        closeModal();
    }

    const handleDelete = async () => {
        
        if (activeStore){
            await dispatch( storeDelete(activeStore));
            dispatch( storeStartLoading() );
        }else{
            await dispatch( userDelete(activeUser));
            dispatch( userStartLoading() );
        }
        closeModal();
    }
    return (
        <Modal 
        isOpen={ modalOpenDelete }
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={ 200 }
        className= "modal modal-delete"
        overlayClassName= "modal-fondo"
        >
            <h1 className='title'> { activeStore ? 'Eliminar Tienda' : 'Eliminar Usuario'} </h1>
            <hr />
            <div className="modal-dialog">
            <div className="modal-content">
                    <div className="modal-body modal-body-delete">
                        <label>{ activeStore ? '¿Estás seguro de eliminar la Tienda? ' : '¿Estás seguro de eliminar el Usuario?'}</label>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default btn-ok"
                            onClick={handleDelete}
                        >
                            <i className="far fa-save"></i>
                            <span> Si</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger btn-ok"
                            onClick={handleReturn}
                        >
                            <i className="far fa-save"></i>
                            <span> No</span>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}