import React from 'react'
import { Provider } from 'react-redux'
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
import 'antd/dist/antd.min.css';

const InitialApp = () => {
  return (
    <Provider store={store}>
      <AppRouter/>
    </Provider>
  )
}
export default InitialApp;
