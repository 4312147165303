import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { uiCloseModalUser } from '../../actions/uiUser';
import { userStartLoading, userStartUpdated } from '../../actions/userCrud';
import "./modal.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const initEvent = {
    code: '',
    name: '',
    address: '',
    town: '',
    city: '',
}

export const EditUserModal = () => {

    const { modalOpenUser} = useSelector(state => state.uiUser);
    const {stores} = useSelector(state => state.store);
    const { activeUser} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [titleValid, setTitleValid] = useState(true);
    const [formValues, setFormValues] = useState(initEvent);
    

    useEffect(() => {
        if(activeUser){
            setFormValues( activeUser.user );
        } else {
            setFormValues( initEvent )
        }
    }, [activeUser, setFormValues])
    
    const { login, store_id, name,  email} = formValues;

    const handleInputChange = ({ target }) => {

        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }

    const closeModal = () => {
        dispatch( uiCloseModalUser() )
        setFormValues( initEvent )
    }
    const handleSubmitForm = async (e) => {
        e.preventDefault();


        if ( name.trim().length < 2 ) {
            return setTitleValid(false);
        }
        
        await dispatch( userStartUpdated( formValues ));
        setTitleValid(true);
        setFormValues( initEvent );
        closeModal();
        dispatch( userStartLoading());
    }
    return (
        <Modal 
        isOpen={ modalOpenUser }
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={ 200 }
        className= "modal modal-user"
        overlayClassName= "modal-fondo"
        >
            <h1 className='title'> Editar Usuario </h1>
            <hr />
            <form className="mmk-form" onSubmit={ handleSubmitForm }>
                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Nombre</label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Nombre"
                            name="name"
                            autoComplete="off"
                            value={ name }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Usuario</label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Usuario"
                            name="login"
                            autoComplete="off"
                            value={ login }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className="form-label" >Tienda</label>
                        <select
                            className='form-select form-select-md shop-sel'
                            placeholder="Tienda"
                            name="store_id"
                            value= {store_id}
                            onChange= { handleInputChange }
                        >
                            {
                                (
                                    stores.map((item,i)=>(
                                    <option key={"stores"+i} value={item.id}>{item.name}</option>
                                ))
                                )
                            }
                        </select>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <label className='form-label'>Email </label>
                        <input 
                            type="text" 
                            className={` form-control ${ !titleValid && 'is-invalid'} `}
                            placeholder="Email"
                            name="email"
                            autoComplete="off"
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row justify-content-end mb-2">
                    <div className="col-12">
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn btn-primary">GUARDAR</button>
                        </div>
                    </div>
                </div>

            </form>
        </Modal>
    )
}